import React, { useState, useEffect, useRef } from "react"
//import { Link, graphql } from "gatsby"
import { graphql } from "gatsby"
import { Link } from "../utils/link"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

import Share from "../components/share"
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

import kebabCase from "lodash/kebabCase"

const BlogPostTemplate = ({ data, location }) => {
  const {t} = useTranslation();
  const post = data.markdownRemark
  const langKey = post.fields?.langKey || `ja`;
  const siteTitle = t(data.site.siteMetadata?.title || `NO PREFIX NOTES`)
  const siteUrl   = data.site.siteMetadata?.siteUrl || ``
  const postTitle = post.frontmatter.title
  const postShareTitle = `${postTitle} | ${siteTitle}`
  const { previous, next } = data
  const tags = data.tags.tags;

  const image = post.frontmatter.image
    ? post.frontmatter.image.childImageSharp.resize
    : null
 
  const [showDisqus, setShowDisqus] = useState(false);
  const [count, setCount] = useState(0);

  const countUp = () => {
    setCount(prevState => prevState + 1)
  }

  const disqusConfig = {
    url: `${data.site.siteMetadata?.siteUrl+location.pathname}`,
    identifier: post.id,
    title: post.title,
  }

  const target = useRef(null);

  useEffect(()=> {
    const cb = (entries) => {
      entries.forEach((entry)=> {
        if (entry.isIntersecting && !showDisqus) {
          setShowDisqus(true);
        }});
    };
    const observer = new IntersectionObserver(cb);
    observer.observe(target.current)
  }, []);

  //useEffect(() => setShowDisqus(true), []);

  return (
    <Layout location={location} title={siteTitle} tags={tags}>
      <Seo
        title={postTitle}
        description={post.frontmatter.description || post.excerpt}
	      image={image}
	      pathname={location.pathname}
        lang={langKey}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article">
        <header>
          <h1 itemProp="headline">{postTitle}</h1>
          <p className="my-blog-post-meta">
            {post.frontmatter.modified || post.frontmatter.date}
            {post.frontmatter.modified ? `(${post.frontmatter.date})`: ""}
      	    <Share
      	      title={postShareTitle}
      	      url={`${siteUrl}${location.pathname}`}
      	      description={`${post.frontmatter.description}`} />
            {/* <button onClick={countUp}>Click{count}</button>
            <audio src="test.mp3" className={`${count % 2 ? 'hidden' : 'block'}`} preload="none" controls></audio>
            */}
      	  </p>

        </header>

        <aside className="my-blog-post-aside">
          <p className="my-blog-post-warn-translation">{t("aboutTranslation")}</p>
        </aside>

        <aside className="my-blog-post-aside">
          <p className="my-blog-post-desc">{post.frontmatter.description}</p>
        </aside>

        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />

          <ol style={{ listStyle: `none` }} className="m-4">
            {post.frontmatter.tags?.map(tag => {
              return (
                <li key={tag} className="inline-block">
                  <Link className="btn-tag" to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                </li>)
            })}
          </ol>
      </article>

      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>

      <article>
        <div ref={target}>
          { showDisqus && <Disqus config={disqusConfig} /> }
          { !showDisqus &&
              <>
              <p>Loading Comments...</p>
              <button onClick={() => setShowDisqus(true) }>Show Comments</button>
              </>
          }
        </div>
      </article>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
    $language: String!
  ) {

    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    site {
      siteMetadata {
        title
	siteUrl
      }
    }
    tags: allMarkdownRemark(
      limit: 2000
        filter: {
          fields: {langKey: {eq: $language}},
        }) {
        tags: group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    markdownRemark(id: { eq: $id }) {
      fields {
        langKey
      }
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        modified(formatString: "YYYY-MM-DD")
        description
        tags
        image: featured {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
