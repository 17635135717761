import {
//  FacebookShareCount,
//  HatenaShareCount,
//  OKShareCount,
//  PinterestShareCount,
//  RedditShareCount,
//  TumblrShareCount,
//  VKShareCount
} from "react-share";


import {
  TwitterShareButton,
//  TwitterIcon,
//  FacebookShareButton,
//  LineShareButton,
  HatenaShareButton,
//  FacebookIcon,
//  LineIcon,
//  HatenaIcon,
} from "react-share"

import React from "react"

const Share = props => {
  const articleTitle = props.title
  const articleUrl = props.url
  const articleDescription = props.description
  const iconSize = 32

  return (<span className="my-blog-post-meta-share">
            <TwitterShareButton
	    url={articleUrl}
	    title={articleTitle}>
	    <svg role="img" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="24" height="24" viewBox="0 0 24 24"><title>Share on Twitter</title><path fill="#000000" d="M22,5.8a8.49,8.49,0,0,1-2.36.64,4.13,4.13,0,0,0,1.81-2.27,8.21,8.21,0,0,1-2.61,1,4.1,4.1,0,0,0-7,3.74A11.64,11.64,0,0,1,3.39,4.62a4.16,4.16,0,0,0-.55,2.07A4.09,4.09,0,0,0,4.66,10.1,4.05,4.05,0,0,1,2.8,9.59v.05a4.1,4.1,0,0,0,3.3,4A3.93,3.93,0,0,1,5,13.81a4.9,4.9,0,0,1-.77-.07,4.11,4.11,0,0,0,3.83,2.84A8.22,8.22,0,0,1,3,18.34a7.93,7.93,0,0,1-1-.06,11.57,11.57,0,0,0,6.29,1.85A11.59,11.59,0,0,0,20,8.45c0-.17,0-.35,0-.53A8.43,8.43,0,0,0,22,5.8Z"/></svg>
            </TwitterShareButton>

            <HatenaShareButton url={articleUrl} title={articleTitle}>
              <svg role="img" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Share on HatenaB</title><path d="M20.47 0C22.42 0 24 1.58 24 3.53v16.94c0 1.95-1.58 3.53-3.53 3.53H3.53C1.58 24 0 22.42 0 20.47V3.53C0 1.58 1.58 0 3.53 0h16.94zm-3.705 14.47c-.78 0-1.41.63-1.41 1.41s.63 1.414 1.41 1.414 1.41-.645 1.41-1.425-.63-1.41-1.41-1.41zM8.61 17.247c1.2 0 2.056-.042 2.58-.12.526-.084.976-.222 1.32-.412.45-.232.78-.564 1.02-.99s.36-.915.36-1.48c0-.78-.21-1.403-.63-1.87-.42-.48-.99-.734-1.74-.794.66-.18 1.156-.45 1.456-.81.315-.344.465-.824.465-1.424 0-.48-.103-.885-.3-1.26-.21-.36-.493-.645-.883-.87-.345-.195-.735-.315-1.215-.405-.464-.074-1.29-.12-2.474-.12H5.654v10.486H8.61zm.736-4.185c.705 0 1.185.088 1.44.262.27.18.39.495.39.93 0 .405-.135.69-.42.855-.27.18-.765.254-1.44.254H8.31v-2.297h1.05zm8.656.706v-7.06h-2.46v7.06H18zM8.925 9.08c.71 0 1.185.08 1.432.24.245.16.367.435.367.83 0 .38-.13.646-.39.804-.265.154-.747.232-1.452.232h-.57V9.08h.615z"/></svg>
            </HatenaShareButton>
          </span>
//    <div className="share">
//      <p className="share__title">SHARE!</p>
//      <div className="social-links">
//        <div className="social-links__icon">
//        </div>
//        <div className="social-links__icon">
//          <FacebookShareButton url={articleUrl}>
//            <FacebookIcon round size={iconSize} />
//          </FacebookShareButton>
//        </div>
//        <div className="social-links__icon">
//          <LineShareButton url={articleUrl} title={articleTitle}>
//            <LineIcon round size={iconSize} />
//          </LineShareButton>
//        </div>
//        <div className="social-links__icon">
//          <HatenaShareButton url={articleUrl} title={articleTitle}>
//            <HatenaIcon round size={iconSize} />
//          </HatenaShareButton>
//        </div>
//      </div>
//    </div>
  )
}

export default Share

